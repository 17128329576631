import { RootState } from "./types";

const entities: RootState["entities"] = {
  manufacturers: {
    "1": {
      id: 1,
      name: "Black Diamond",
      camStyles: [1, 2, 3, 7, 8],
    },
    "2": {
      id: 2,
      name: "Totem",
      camStyles: [4],
    },
    "3": {
      id: 3,
      name: "Metolius",
      camStyles: [5, 6],
    },
    "4": {
      id: 4,
      name: "DMM",
      camStyles: [9, 11],
    },
    "5": {
      id: 5,
      name: "Fixe",
      camStyles: [10],
    },
    "6": {
      id: 6,
      name: "Wild Country",
      camStyles: [12],
    },
    "7": {
      id: 7,
      name: "Kouba",
      camStyles: [13],
    },
    "8": {
      id: 8,
      name: "Camp",
      camStyles: [14],
    },
  },
  camStyles: {
    "1": {
      id: 1,
      name: "Camalot C4",
      manufacturerId: 1,
      lobes: 4,
      cams: [1, 4, 5, 6, 7, 8, 9, 10, 2, 3],
    },
    "2": {
      id: 2,
      name: "Camalot C3",
      manufacturerId: 1,
      lobes: 3,
      cams: [11, 12, 13, 14, 15],
    },
    "3": {
      id: 3,
      name: "Camalot X4",
      manufacturerId: 1,
      lobes: 4,
      cams: [16, 17, 18, 19, 20, 21],
    },
    "4": {
      id: 4,
      name: "Totem Cam",
      manufacturerId: 2,
      lobes: 4,
      cams: [22, 23, 24, 25, 26, 27, 28],
    },
    "5": {
      id: 5,
      name: "Master Cam",
      manufacturerId: 3,
      lobes: 4,
      cams: [29, 30, 31, 32, 33, 34, 35, 36, 37, 38],
    },
    "6": {
      id: 6,
      name: "TCU",
      manufacturerId: 3,
      lobes: 3,
      cams: [39, 40, 41, 42, 43, 44],
    },
    "7": {
      id: 7,
      name: "C4 Ultralight",
      manufacturerId: 1,
      lobes: 4,
      cams: [47, 48, 45, 46, 49, 50, 51],
    },
    "8": {
      id: 8,
      name: "Camalot Z4",
      manufacturerId: 1,
      lobes: 4,
      cams: [52, 53, 54, 55, 56, 57, 58],
    },
    "9": {
      id: 9,
      name: "Dragonfly",
      manufacturerId: 4,
      lobes: 4,
      cams: [59, 60, 61, 62, 63, 64],
    },
    "10": {
      id: 10,
      name: "Alien LITE",
      manufacturerId: 5,
      lobes: 4,
      cams: [65, 66, 67, 68, 69, 70],
    },
    "11": {
      id: 11,
      name: "Dragon ",
      manufacturerId: 4,
      lobes: 4,
      cams: [71, 72, 73, 74, 75, 76, 77, 93, 94, 95],
    },
    "12": {
      id: 12,
      name: "Friend",
      manufacturerId: 6,
      lobes: 4,
      cams: [78, 79, 80, 81, 82, 83, 84],
    },
    "13": {
      id: 13,
      name: "Friend Flex",
      manufacturerId: 7,
      lobes: 4,
      cams: [85, 86, 87, 88, 89, 90, 91, 92],
    },
    "14": {
      id: 14,
      name: "Tricam",
      manufacturerId: 8,
      lobes: 1,
      cams: [96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108],
    },
  },
  cams: {
    "1": {
      id: 1,
      name: ".3",
      camStyleId: 1,
      rangeMin: 13.8,
      rangeMax: 23.4,
      weight: 69.8,
      strength: 8,
      color: "blue",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "2": {
      id: 2,
      name: ".4",
      camStyleId: 1,
      rangeMin: 15.5,
      rangeMax: 26.7,
      weight: 77.5,
      strength: 9,
      color: "grey",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "3": {
      id: 3,
      name: ".5",
      camStyleId: 1,
      rangeMin: 19.6,
      rangeMax: 33.5,
      weight: 93,
      strength: 12,
      color: "purple",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "4": {
      id: 4,
      name: ".75",
      camStyleId: 1,
      rangeMin: 23.9,
      rangeMax: 41.2,
      weight: 107.5,
      strength: 12,
      color: "green",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "5": {
      id: 5,
      name: "#1",
      camStyleId: 1,
      rangeMin: 30.2,
      rangeMax: 52.1,
      weight: 123.9,
      strength: 12,
      color: "red",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "6": {
      id: 6,
      name: "#2",
      camStyleId: 1,
      rangeMin: 37.2,
      rangeMax: 64.9,
      weight: 140.3,
      strength: 12,
      color: "yellow",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "7": {
      id: 7,
      name: "#3",
      camStyleId: 1,
      rangeMin: 50.7,
      rangeMax: 87.9,
      weight: 181.1,
      strength: 12,
      color: "blue",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "8": {
      id: 8,
      name: "#4",
      camStyleId: 1,
      rangeMin: 66,
      rangeMax: 114.7,
      weight: 257.8,
      strength: 14,
      color: "grey",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "9": {
      id: 9,
      name: "#5",
      camStyleId: 1,
      rangeMin: 85.4,
      rangeMax: 148.5,
      weight: 348.1,
      strength: 14,
      color: "purple",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "10": {
      id: 10,
      name: "#6",
      camStyleId: 1,
      rangeMin: 114.1,
      rangeMax: 195,
      weight: 529.9,
      strength: 14,
      color: "green",
      buyLink: "https://bit.ly/3nhwAxN",
    },
    "11": {
      id: 11,
      name: "000",
      camStyleId: 2,
      rangeMin: 7.8,
      rangeMax: 12.9,
      weight: 55,
      strength: 4,
      color: "grey",
      buyLink: null,
    },
    "12": {
      id: 12,
      name: "00",
      camStyleId: 2,
      rangeMin: 9,
      rangeMax: 13.7,
      weight: 57,
      strength: 6,
      color: "purple",
      buyLink: null,
    },
    "13": {
      id: 13,
      name: "0",
      camStyleId: 2,
      rangeMin: 10.7,
      rangeMax: 15.8,
      weight: 59,
      strength: 7,
      color: "green",
      buyLink: null,
    },
    "14": {
      id: 14,
      name: "1",
      camStyleId: 2,
      rangeMin: 12,
      rangeMax: 18.8,
      weight: 62,
      strength: 10,
      color: "red",
      buyLink: null,
    },
    "15": {
      id: 15,
      name: "2",
      camStyleId: 2,
      rangeMin: 14.2,
      rangeMax: 22.6,
      weight: 66,
      strength: 10,
      color: "yellow",
      buyLink: null,
    },
    "16": {
      id: 16,
      name: ".1",
      camStyleId: 3,
      rangeMin: 8.4,
      rangeMax: 13.8,
      weight: 51,
      strength: 5,
      color: "red",
      buyLink: "https://amzn.to/2TssyVG",
    },
    "17": {
      id: 17,
      name: ".2",
      camStyleId: 3,
      rangeMin: 9.9,
      rangeMax: 16.5,
      weight: 54,
      strength: 6,
      color: "yellow",
      buyLink: "https://amzn.to/2TssyVG",
    },
    "18": {
      id: 18,
      name: ".3",
      camStyleId: 3,
      rangeMin: 12.4,
      rangeMax: 21.2,
      weight: 75,
      strength: 8,
      color: "blue",
      buyLink: "https://amzn.to/2TssyVG",
    },
    "19": {
      id: 19,
      name: ".4",
      camStyleId: 3,
      rangeMin: 15.5,
      rangeMax: 26.6,
      weight: 82,
      strength: 9,
      color: "grey",
      buyLink: "https://amzn.to/2TssyVG",
    },
    "20": {
      id: 20,
      name: ".5",
      camStyleId: 3,
      rangeMin: 19.8,
      rangeMax: 33.7,
      weight: 91,
      strength: 9,
      color: "purple",
      buyLink: "https://amzn.to/2TssyVG",
    },
    "21": {
      id: 21,
      name: ".75",
      camStyleId: 3,
      rangeMin: 24,
      rangeMax: 41.2,
      weight: 112,
      strength: 9,
      color: "green",
      buyLink: "https://amzn.to/2TssyVG",
    },
    "22": {
      id: 22,
      name: "0.50",
      camStyleId: 4,
      rangeMin: 11.7,
      rangeMax: 18.9,
      weight: 69,
      strength: 6,
      color: "black",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "23": {
      id: 23,
      name: ".65",
      camStyleId: 4,
      rangeMin: 13.8,
      rangeMax: 22.5,
      weight: 75,
      strength: 8,
      color: "blue",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "24": {
      id: 24,
      name: "0.80",
      camStyleId: 4,
      rangeMin: 17,
      rangeMax: 27.7,
      weight: 83,
      strength: 9,
      color: "yellow",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "25": {
      id: 25,
      name: "1.00",
      camStyleId: 4,
      rangeMin: 20.9,
      rangeMax: 34.2,
      weight: 95,
      strength: 10,
      color: "purple",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "26": {
      id: 26,
      name: "1.25",
      camStyleId: 4,
      rangeMin: 25.7,
      rangeMax: 42.3,
      weight: 109,
      strength: 13,
      color: "green",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "27": {
      id: 27,
      name: "1.50",
      camStyleId: 4,
      rangeMin: 31.6,
      rangeMax: 52.2,
      weight: 132,
      strength: 13,
      color: "red",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "28": {
      id: 28,
      name: "1.80",
      camStyleId: 4,
      rangeMin: 39.7,
      rangeMax: 64.2,
      weight: 144,
      strength: 13,
      color: "orange",
      buyLink: "https://www.totemmt.com/product/totem-cam/",
    },
    "29": {
      id: 29,
      name: "00",
      camStyleId: 5,
      rangeMin: 8.5,
      rangeMax: 12,
      weight: 45,
      strength: 5,
      color: "grey",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "30": {
      id: 30,
      name: "0",
      camStyleId: 5,
      rangeMin: 10,
      rangeMax: 15,
      weight: 45,
      strength: 5,
      color: "purple",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "31": {
      id: 31,
      name: "#1",
      camStyleId: 5,
      rangeMin: 12.5,
      rangeMax: 18,
      weight: 52,
      strength: 8,
      color: "blue",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "32": {
      id: 32,
      name: "#2",
      camStyleId: 5,
      rangeMin: 15.5,
      rangeMax: 22.5,
      weight: 55,
      strength: 10,
      color: "yellow",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "33": {
      id: 33,
      name: "#3",
      camStyleId: 5,
      rangeMin: 18.5,
      rangeMax: 26.5,
      weight: 65,
      strength: 10,
      color: "orange",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "34": {
      id: 34,
      name: "#4",
      camStyleId: 5,
      rangeMin: 23.5,
      rangeMax: 33.5,
      weight: 75,
      strength: 10,
      color: "red",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "35": {
      id: 35,
      name: "#5",
      camStyleId: 5,
      rangeMin: 28,
      rangeMax: 39.5,
      weight: 85,
      strength: 10,
      color: "black",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "36": {
      id: 36,
      name: "#6",
      camStyleId: 5,
      rangeMin: 32.5,
      rangeMax: 48,
      weight: 96,
      strength: 10,
      color: "green",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "37": {
      id: 37,
      name: "#7",
      camStyleId: 5,
      rangeMin: 40,
      rangeMax: 57.5,
      weight: 112,
      strength: 10,
      color: "blue",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "38": {
      id: 38,
      name: "#8",
      camStyleId: 5,
      rangeMin: 48.5,
      rangeMax: 71.5,
      weight: 129,
      strength: 10,
      color: "purple",
      buyLink: "https://amzn.to/2PSgU4i",
    },
    "39": {
      id: 39,
      name: "00",
      camStyleId: 6,
      rangeMin: 8.5,
      rangeMax: 12,
      weight: 41,
      strength: 5,
      color: "grey",
      buyLink: "https://amzn.to/3azVErV",
    },
    "40": {
      id: 40,
      name: "0",
      camStyleId: 6,
      rangeMin: 10,
      rangeMax: 15,
      weight: 43,
      strength: 5,
      color: "purple",
      buyLink: "https://amzn.to/3azVErV",
    },
    "41": {
      id: 41,
      name: "#1",
      camStyleId: 6,
      rangeMin: 12.5,
      rangeMax: 18,
      weight: 50,
      strength: 8,
      color: "blue",
      buyLink: "https://amzn.to/3azVErV",
    },
    "42": {
      id: 42,
      name: "#2",
      camStyleId: 6,
      rangeMin: 15.5,
      rangeMax: 22.5,
      weight: 57,
      strength: 10,
      color: "yellow",
      buyLink: "https://amzn.to/3azVErV",
    },
    "43": {
      id: 43,
      name: "#3",
      camStyleId: 6,
      rangeMin: 18.5,
      rangeMax: 26.5,
      weight: 59,
      strength: 10,
      color: "orange",
      buyLink: "https://amzn.to/3azVErV",
    },
    "44": {
      id: 44,
      name: "#4",
      camStyleId: 6,
      rangeMin: 23.5,
      rangeMax: 33.5,
      weight: 68,
      strength: 10,
      color: "red",
      buyLink: "https://amzn.to/3azVErV",
    },
    "45": {
      id: 45,
      name: ".4",
      camStyleId: 7,
      rangeMin: 15.5,
      rangeMax: 26.7,
      weight: 61,
      strength: 8,
      color: "grey",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "46": {
      id: 46,
      name: ".5",
      camStyleId: 7,
      rangeMin: 19.6,
      rangeMax: 33.5,
      weight: 74,
      strength: 10,
      color: "purple",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "47": {
      id: 47,
      name: ".75",
      camStyleId: 7,
      rangeMin: 23.9,
      rangeMax: 41.2,
      weight: 89,
      strength: 12,
      color: "green",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "48": {
      id: 48,
      name: "#1",
      camStyleId: 7,
      rangeMin: 30.2,
      rangeMax: 52.1,
      weight: 101,
      strength: 12,
      color: "red",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "49": {
      id: 49,
      name: "#2",
      camStyleId: 7,
      rangeMin: 37.2,
      rangeMax: 64.9,
      weight: 126,
      strength: 12,
      color: "yellow",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "50": {
      id: 50,
      name: "#3",
      camStyleId: 7,
      rangeMin: 50.7,
      rangeMax: 87.9,
      weight: 167,
      strength: 12,
      color: "blue",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "51": {
      id: 51,
      name: "#4",
      camStyleId: 7,
      rangeMin: 66,
      rangeMax: 114.7,
      weight: 225,
      strength: 12,
      color: "grey",
      buyLink: "https://bit.ly/3nkTSTd",
    },
    "52": {
      id: 52,
      name: "0",
      camStyleId: 8,
      rangeMin: 7.5,
      rangeMax: 11.8,
      weight: 43,
      strength: 5,
      color: "green",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "53": {
      id: 53,
      name: ".1",
      camStyleId: 8,
      rangeMin: 8.8,
      rangeMax: 13.8,
      weight: 45,
      strength: 5,
      color: "red",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "54": {
      id: 54,
      name: ".2",
      camStyleId: 8,
      rangeMin: 10.4,
      rangeMax: 16.3,
      weight: 48,
      strength: 6,
      color: "yellow",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "55": {
      id: 55,
      name: ".3",
      camStyleId: 8,
      rangeMin: 12.4,
      rangeMax: 22.6,
      weight: 54,
      strength: 8,
      color: "blue",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "56": {
      id: 56,
      name: ".4",
      camStyleId: 8,
      rangeMin: 15.3,
      rangeMax: 27.7,
      weight: 61,
      strength: 9,
      color: "grey",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "57": {
      id: 57,
      name: ".5",
      camStyleId: 8,
      rangeMin: 18.8,
      rangeMax: 33.9,
      weight: 77,
      strength: 10,
      color: "purple",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "58": {
      id: 58,
      name: ".75",
      camStyleId: 8,
      rangeMin: 23.1,
      rangeMax: 42.1,
      weight: 93,
      strength: 10,
      color: "green",
      buyLink: "https://bit.ly/3sLwFe9",
    },
    "59": {
      id: 59,
      name: "#1",
      camStyleId: 9,
      rangeMin: 7.8,
      rangeMax: 11,
      weight: 55,
      strength: 6,
      color: "green",
      buyLink: "https://amzn.to/2TqgdkX",
    },
    "60": {
      id: 60,
      name: "#2",
      camStyleId: 9,
      rangeMin: 8.7,
      rangeMax: 12.9,
      weight: 56,
      strength: 6,
      color: "red",
      buyLink: "https://amzn.to/2TqgdkX",
    },
    "61": {
      id: 61,
      name: "#3",
      camStyleId: 9,
      rangeMin: 10.2,
      rangeMax: 15.2,
      weight: 65,
      strength: 8,
      color: "gold",
      buyLink: "https://amzn.to/2TqgdkX",
    },
    "62": {
      id: 62,
      name: "#4",
      camStyleId: 9,
      rangeMin: 12.1,
      rangeMax: 17.9,
      weight: 67,
      strength: 8,
      color: "blue",
      buyLink: "https://amzn.to/2TqgdkX",
    },
    "63": {
      id: 63,
      name: "#5",
      camStyleId: 9,
      rangeMin: 15.1,
      rangeMax: 22.5,
      weight: 70,
      strength: 9,
      color: "silver",
      buyLink: "https://amzn.to/2TqgdkX",
    },
    "64": {
      id: 64,
      name: "#6",
      camStyleId: 9,
      rangeMin: 19,
      rangeMax: 28.3,
      weight: 73,
      strength: 9,
      color: "purple",
      buyLink: "https://amzn.to/2TqgdkX",
    },
    "65": {
      id: 65,
      name: "1/3",
      camStyleId: 10,
      rangeMin: 8,
      rangeMax: 14,
      weight: 46,
      strength: 5,
      color: "black",
      buyLink: null,
    },
    "66": {
      id: 66,
      name: "3/8",
      camStyleId: 10,
      rangeMin: 10,
      rangeMax: 17,
      weight: 48,
      strength: 6,
      color: "blue",
      buyLink: null,
    },
    "67": {
      id: 67,
      name: "1/2",
      camStyleId: 10,
      rangeMin: 13,
      rangeMax: 22,
      weight: 52,
      strength: 7,
      color: "green",
      buyLink: null,
    },
    "68": {
      id: 68,
      name: "3/4",
      camStyleId: 10,
      rangeMin: 15,
      rangeMax: 25,
      weight: 58,
      strength: 10,
      color: "yellow",
      buyLink: null,
    },
    "69": {
      id: 69,
      name: "7/8",
      camStyleId: 10,
      rangeMin: 17,
      rangeMax: 30,
      weight: 59,
      strength: 10,
      color: "grey",
      buyLink: null,
    },
    "70": {
      id: 70,
      name: "#1",
      camStyleId: 10,
      rangeMin: 20,
      rangeMax: 33,
      weight: 61,
      strength: 10,
      color: "red",
      buyLink: null,
    },
    "71": {
      id: 71,
      name: "00",
      camStyleId: 11,
      rangeMin: 14,
      rangeMax: 21,
      weight: 75,
      strength: 10,
      color: "blue",
      buyLink: "https://amzn.to/3aux0co",
    },
    "72": {
      id: 72,
      name: "0",
      camStyleId: 11,
      rangeMin: 16,
      rangeMax: 25,
      weight: 85,
      strength: 14,
      color: "silver",
      buyLink: "https://amzn.to/3aux0co",
    },
    "73": {
      id: 73,
      name: "#1",
      camStyleId: 11,
      rangeMin: 20,
      rangeMax: 33,
      weight: 103,
      strength: 14,
      color: "purple",
      buyLink: "https://amzn.to/3aux0co",
    },
    "74": {
      id: 74,
      name: "#2",
      camStyleId: 11,
      rangeMin: 24,
      rangeMax: 41,
      weight: 117,
      strength: 14,
      color: "green",
      buyLink: "https://amzn.to/3aux0co",
    },
    "75": {
      id: 75,
      name: "#3",
      camStyleId: 11,
      rangeMin: 29,
      rangeMax: 50,
      weight: 128,
      strength: 14,
      color: "red",
      buyLink: "https://amzn.to/3aux0co",
    },
    "76": {
      id: 76,
      name: "#4",
      camStyleId: 11,
      rangeMin: 38,
      rangeMax: 64,
      weight: 154,
      strength: 14,
      color: "gold",
      buyLink: "https://amzn.to/3aux0co",
    },
    "77": {
      id: 77,
      name: "#5",
      camStyleId: 11,
      rangeMin: 50,
      rangeMax: 85,
      weight: 208,
      strength: 14,
      color: "blue",
      buyLink: "https://amzn.to/3aux0co",
    },
    "78": {
      id: 78,
      name: ".4",
      camStyleId: 12,
      rangeMin: 15.8,
      rangeMax: 26.37,
      weight: 75,
      strength: 10,
      color: "silver",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "79": {
      id: 79,
      name: ".5",
      camStyleId: 12,
      rangeMin: 20.6,
      rangeMax: 34.5,
      weight: 88,
      strength: 12,
      color: "purple",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "80": {
      id: 80,
      name: ".75",
      camStyleId: 12,
      rangeMin: 25.8,
      rangeMax: 43,
      weight: 102,
      strength: 12,
      color: "green",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "81": {
      id: 81,
      name: "#1",
      camStyleId: 12,
      rangeMin: 31.7,
      rangeMax: 53.6,
      weight: 123,
      strength: 12,
      color: "red",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "82": {
      id: 82,
      name: "#2",
      camStyleId: 12,
      rangeMin: 41.5,
      rangeMax: 69.3,
      weight: 142,
      strength: 12,
      color: "gold",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "83": {
      id: 83,
      name: "#3",
      camStyleId: 12,
      rangeMin: 52.7,
      rangeMax: 88,
      weight: 192,
      strength: 12,
      color: "blue",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "84": {
      id: 84,
      name: "#4",
      camStyleId: 12,
      rangeMin: 66.8,
      rangeMax: 112.1,
      weight: 260,
      strength: 12,
      color: "silver",
      buyLink: "https://amzn.to/2TqzYsD",
    },
    "85": {
      id: 85,
      name: ".25",
      camStyleId: 13,
      rangeMin: 12,
      rangeMax: 16.5,
      weight: 60,
      strength: 7,
      color: "green",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "86": {
      id: 86,
      name: ".5",
      camStyleId: 13,
      rangeMin: 14,
      rangeMax: 20,
      weight: 65,
      strength: 9,
      color: "blue",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "87": {
      id: 87,
      name: ".75",
      camStyleId: 13,
      rangeMin: 17,
      rangeMax: 24.5,
      weight: 68,
      strength: 10,
      color: "red",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "88": {
      id: 88,
      name: "#1",
      camStyleId: 13,
      rangeMin: 20,
      rangeMax: 29,
      weight: 96,
      strength: 12,
      color: "grey",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "89": {
      id: 89,
      name: "#2",
      camStyleId: 13,
      rangeMin: 27,
      rangeMax: 41,
      weight: 112,
      strength: 12,
      color: "gold",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "90": {
      id: 90,
      name: "#3",
      camStyleId: 13,
      rangeMin: 35,
      rangeMax: 53,
      weight: 148,
      strength: 12,
      color: "blue",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "91": {
      id: 91,
      name: "#4",
      camStyleId: 13,
      rangeMin: 48,
      rangeMax: 67,
      weight: 175,
      strength: 12,
      color: "orange",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "92": {
      id: 92,
      name: "#5",
      camStyleId: 13,
      rangeMin: 61,
      rangeMax: 91,
      weight: 219,
      strength: 12,
      color: "black",
      buyLink: "https://www.koubaclimbing.com/index.php/friend-flex",
    },
    "93": {
      id: 93,
      name: "#6",
      camStyleId: 11,
      rangeMin: 68,
      rangeMax: 114,
      weight: 276,
      strength: 14,
      color: "silver",
      buyLink: "https://amzn.to/3aux0co",
    },
    "94": {
      id: 94,
      name: "#7",
      camStyleId: 11,
      rangeMin: 88,
      rangeMax: 149,
      weight: 362,
      strength: 14,
      color: "purple",
      buyLink: "https://amzn.to/3aux0co",
    },
    "95": {
      id: 95,
      name: "#8",
      camStyleId: 11,
      rangeMin: 116,
      rangeMax: 195,
      weight: 515,
      strength: 14,
      color: "green",
      buyLink: "https://amzn.to/3aux0co",
    },
    "96": {
      id: 96,
      name: ".125",
      camStyleId: 14,
      rangeMin: 10,
      rangeMax: 16,
      weight: 10.5,
      strength: 3,
      color: "white",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "97": {
      id: 97,
      name: ".25",
      camStyleId: 14,
      rangeMin: 13.5,
      rangeMax: 22,
      weight: 19,
      strength: 5,
      color: "black",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "98": {
      id: 98,
      name: ".5",
      camStyleId: 14,
      rangeMin: 18,
      rangeMax: 27,
      weight: 30,
      strength: 9,
      color: "pink",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "99": {
      id: 99,
      name: "#1",
      camStyleId: 14,
      rangeMin: 21,
      rangeMax: 32,
      weight: 37,
      strength: 10,
      color: "red",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "100": {
      id: 100,
      name: "1.5",
      camStyleId: 14,
      rangeMin: 26,
      rangeMax: 40,
      weight: 55,
      strength: 14,
      color: "brown",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "101": {
      id: 101,
      name: "#2",
      camStyleId: 14,
      rangeMin: 29,
      rangeMax: 45,
      weight: 57,
      strength: 14,
      color: "purple",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "102": {
      id: 102,
      name: "2.5",
      camStyleId: 14,
      rangeMin: 32,
      rangeMax: 48,
      weight: 77,
      strength: 15,
      color: "blue",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "103": {
      id: 103,
      name: "#3",
      camStyleId: 14,
      rangeMin: 38,
      rangeMax: 54,
      weight: 90,
      strength: 16,
      color: "navy",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "104": {
      id: 104,
      name: "3.5",
      camStyleId: 14,
      rangeMin: 41,
      rangeMax: 60,
      weight: 117,
      strength: 18,
      color: "grey",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "105": {
      id: 105,
      name: "#4",
      camStyleId: 14,
      rangeMin: 45,
      rangeMax: 64,
      weight: 138,
      strength: 22,
      color: "green",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "106": {
      id: 106,
      name: "#5",
      camStyleId: 14,
      rangeMin: 57,
      rangeMax: 89,
      weight: 120,
      strength: 22,
      color: "orange",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "107": {
      id: 107,
      name: "#6",
      camStyleId: 14,
      rangeMin: 73,
      rangeMax: 105,
      weight: 200,
      strength: 18,
      color: "yellow",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
    "108": {
      id: 108,
      name: "#7",
      camStyleId: 14,
      rangeMin: 92,
      rangeMax: 140,
      weight: 264,
      strength: 16,
      color: "gold",
      buyLink:
        "https://www.camp-usa.com/outdoor/product/rock-protection/tricam/",
    },
  },
};

export default entities;
